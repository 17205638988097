import React, { Fragment, useContext, useEffect } from "react"
import { Link } from "gatsby"
import Img from "gatsby-image"
import classNames from "classnames"

import Message from "../../../components/Elements/Message"
import Layout from "../../Layout"
import Container from "../../Layout/Container"

import styles from "../../MedEnsure/Request/RequestComplete/utils/bookingComplete.module.scss"
import { useCompleteImages } from "./hooks/useCompleteImages"
import { EpharmacyContext } from "../EpharmacyContext/EpharmacyContext"
import {
  GATSBY_MGWEB_URL,
  GATSBY_PULSECARE_CONTACT_NUMBER,
  GATSBY_PULSECARE_EMAIL,
} from "gatsby-env-variables"

const OrderComplete = ({ pageContext }) => {
  const { module, content, mgWebPath, backPath } = pageContext
  const data = useCompleteImages()
  const { epharmacyDispatch } = useContext(EpharmacyContext)

  useEffect(() => {
    const restartDetails = async () => {
      await epharmacyDispatch({ type: "RESET_DETAILS" })
    }

    restartDetails()
  }, [epharmacyDispatch])

  return (
    <Layout {...module} pageContext={pageContext}>
      <Container isCentered>
        <div className={classNames("columns", styles["column"])}>
          {content?.icon && (
            <div className="column is-4-desktop is-12-mobile">
              <figure className={classNames("image mx-3")}>
                <Img
                  fluid={data[content.icon].childImageSharp.fluid}
                  alt={`${module.name} Complete`}
                  className={styles["image"]}
                />
              </figure>
            </div>
          )}
          <div className="column">
            <p className="mb-2">
              We will be sending a message once your order is verified. Please
              email{" "}
              <span className="has-text-primary has-text-weight-bold">
                {GATSBY_PULSECARE_EMAIL}
              </span>{" "}
              if you do not hear from us within 1-2 working days.
            </p>
            <Message color="primary">
              {
                "The Pulse Care Program only provides Sacubitril-Valsartan. If you have other medicines, you may order at"
              }{" "}
              <span className="has-text-weight-bold has-text-primary">
                <a href={mgWebPath} target="_blank" rel="noreferrer noopener">
                  {GATSBY_MGWEB_URL}.
                </a>
              </span>
            </Message>
          </div>
        </div>
        <center className="is-flex is-justify-content-center mt-3">
          <Link to={backPath} className="button is-primary is-medium mx-1">
            {"Finish"}
          </Link>
          <Link
            to="https://medgrocer.com/"
            target="_blank"
            rel="noreferrer noopener"
            className="button is-primary is-outlined is-medium mx-1"
          >
            Order at medgrocer.com
          </Link>
        </center>
      </Container>
    </Layout>
  )
}

export default OrderComplete
